// kernel.scss
//  Contains all definitions that are to be applied across all devices
//
// Authors: Rico Bergmann <rico.bergmann@naju-sachsen.de>

/*
 * Custom bootstrap theming
 */
@import "bs_customization";

/*
 * Bootstrap
 */
@import "../node_modules/bootstrap/scss/bootstrap";

/*
 * Bootstrap extensions
 */
@import "bs_extensions";

/*
 * Core styles
 */
@import "defaults";

/*
 * Core layout
 */
@import "layout";

/*
 * Mobile layout
 */
@media screen and (max-width: (map-get($grid-breakpoints, 'lg') - 1)) {

  @import "mobile";

}

/*
 * Desktop layout
 */
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  @import "desktop";

}

/*
 * Styles used accross all medias and pages
 */
@import "styles";

/*
 * Styles used for the home page
 */
@import "home";
