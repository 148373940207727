// _home.scss
//  Contains styles specific to the home page
//
// Authors: Rico Bergmann <rico.bergmann@naju-sachsen.de>

//
// Navigation
//

nav#quick-nav {
	border: 1px solid $border-color;
	border-radius: 0.25rem;
	background-color: rgba($default-beige, 0.7);
}

nav#quick-nav a {
	color: $dark-red;
	border: none;
	transition: none;
}

nav#quick-nav a:hover {
	border: none;
	font-weight: bold;
}

nav#quick-nav a::before {
	display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

nav#quick-nav .nav-item {
	list-style: none;
}

// Mobile
@media screen and (max-width: (map-get($grid-breakpoints, 'lg') - 1)) {

	nav#quick-nav {
		margin-left: 15px;
		margin-right: 15px;
		margin-top: 1rem;
		margin-bottom: 1rem;
		border-left: 1px solid $border-color;
		border-top-left-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}

	nav#quick-nav::before {
		display: block;
		content: "Navigation";
		text-align: center;
		font-size: 2rem;
		color: $dark-red;
	}

	nav#quick-nav .nav-primary {
		display: none;
		padding-bottom: 5px;
	}

	nav#quick-nav:hover .nav-primary,
	nav#quick-nav:focus .nav-primary {
		display: block;
	}

}

// Desktop
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

	nav#quick-nav .nav-primary {
		display: flex;
		flex-direction: row;
		overflow: auto;
		align-items: center;
		justify-content: space-evenly;
		padding: 0;
		margin: 0;
	}

	nav#quick-nav:hover {
		background-color: rgba(240, 247, 224, .8);
		border-color: $dark-red !important;
	}

}

//
// Intro cards
//

.card.intro-card {
	margin-bottom: 10px;
	transition: transform .2s;
	border: none;
}

.card.intro-card:hover {
	transform: scale(1.05);
}

.card.intro-card .card-body-wrapper {
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: flex-end;
	box-shadow: none;
	border-radius: 5px;
}

.card.intro-card .card-body-wrapper .card-body {
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.01), white);
	padding-top: 6rem;
}

.intro-card-btn {
	margin-left: 1rem;
	margin-bottom: .5rem;
}


// Mobile
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

		.card.intro-card .card-body-wrapper {
			min-height: 400px;
		}

}
