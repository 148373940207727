// _mobile.scss
//  Contains all styles that should be applied on mobile devices
//
// Authors: Rico Bergmann <rico.bergmann@naju-sachsen.de>

//
// Header
//

header#top-bar {
  display: flex;
  justify-content: flex-end;
}

header#top-bar .naju-logo {
  margin-right: 2rem;
  margin-top: -10px;
}

//
// Navigation
//

#nav-content-wrapper {
  padding-bottom: 1rem;
}

nav#main-nav {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-left: 1px solid $border-color;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

nav#main-nav::before {
  display: block;
  content: "Navigation";
  text-align: center;
  font-size: 2rem;
  color: $dark-red;
}

nav#main-nav .nav-primary {
  display: none;
  padding-bottom: 5px;
}

nav#main-nav:hover .nav-primary,
nav#main-nav:focus .nav-primary {
  display: block;
}

nav#main-nav > ul.nav:not(.sub-nav) > .nav-item {
  border-bottom: 1px dotted $dark-red;
}


//
// Sidebar
//

aside#at-a-glance {
  display: none !important;
}

//
// Footer
//

.basic-footer {
  display: relative;
}
